const EN_DEFAULT_LIST = [
    {
        title: "빠른독해 바른독해 기초세우기",
        imageUrl: "https://contents.kyobobook.co.kr/sih/fit-in/458x0/pdt/9791125336372.jpg"
    },
    {
        title: "더 상승 수능유형편",
        imageUrl: "https://static.v2.solvook.com/workbooks/3237063810070087188/the_su_thumbnail.jpg"
    },
    {
        title: "빠른독해 바른독해 유형독해",
        imageUrl: "https://contents.kyobobook.co.kr/sih/fit-in/458x0/pdt/9791125337638.jpg"
    },
    {
        title: "Booster 유형독해",
        imageUrl: "https://c-cdn.solvook.com/book_sub/thumbnail/2022/12/09/4a1ad5d2766b8336e012039a30b72e693b1cc241aabdb6dc698dcfb44190b9c2.jpg"
    },
    {
        title: "수능만만 영어독해 20회",
        imageUrl: "https://contents.kyobobook.co.kr/sih/fit-in/458x0/pdt/9791125337409.jpg"
    }
];
const KO_DEFAULT_LIST = [
    {
        title: "[비상교육] 고등 문학 (한철우)",
        imageUrl: "/static/images/ko_thumb1.jpg"
    },
    {
        title: "[천재교과서] 고등 문학 (김동환)",
        imageUrl: "/static/images/ko_thumb2.jpg"
    },
    {
        title: "[창비] 고등 문학 (최원식)",
        imageUrl: "/static/images/ko_thumb3.jpg"
    },
    {
        title: "[금성출판사] 고등 문학 (류수열)",
        imageUrl: "/static/images/ko_thumb4.jpg"
    },
    {
        title: "[미래엔] 고등 문학 (방민호)",
        imageUrl: "/static/images/ko_thumb5.jpg"
    }
];
const MA_DEFAULT_LIST = [
    {
        title: "고등 수학(상)",
        imageUrl: "/static/images/ma_thumb1.png"
    },
    {
        title: "고등 수학 Ⅰ",
        imageUrl: "/static/images/ma_thumb2.png"
    },
    {
        title: "고등 수학 Ⅱ",
        imageUrl: "/static/images/ma_thumb3.png"
    },
    {
        title: "중등 수학 1-1",
        imageUrl: "/static/images/ma_thumb4.png"
    },
    {
        title: "중등 수학 2-1",
        imageUrl: "/static/images/ma_thumb5.png"
    }
];
const SO_DEFAULT_LIST = [
    {
        title: "통합사회1",
        imageUrl: "/static/images/so_thumb1.png"
    },
    {
        title: "통합사회2",
        imageUrl: "/static/images/so_thumb2.png"
    },
    {
        title: "한국사1",
        imageUrl: "/static/images/so_thumb3.png"
    },
    {
        title: "사회\xb7문화",
        imageUrl: "/static/images/so_thumb4.png"
    },
    {
        title: "생활과 윤리",
        imageUrl: "/static/images/so_thumb5.png"
    }
];
const SC_DEFAULT_LIST = [
    {
        title: "통합과학1",
        imageUrl: "/static/images/sc_thumb1.png"
    },
    {
        title: "물리학1",
        imageUrl: "/static/images/sc_thumb2.png"
    },
    {
        title: "화학1",
        imageUrl: "/static/images/sc_thumb3.png"
    },
    {
        title: "생명과학1",
        imageUrl: "/static/images/sc_thumb4.png"
    },
    {
        title: "지구과학1",
        imageUrl: "/static/images/sc_thumb5.png"
    }
];
export { EN_DEFAULT_LIST, KO_DEFAULT_LIST, MA_DEFAULT_LIST, SC_DEFAULT_LIST, SO_DEFAULT_LIST };
